.container {
  position: absolute;
  height: 100%;
  width: 100%;
  //   background-image: url("../../assets/img/background.png");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
}

.row {
  height: 100%;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  display: none;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 2rem;
  color: rgba(78, 71, 71, 0.74);
}
.bannerContainer {
  height: 100%;
  flex-direction: column;
  position: fixed;
  width: 60%;
  background-image: url("../../assets/img/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .bannerLogo {
    margin-bottom: 5rem;
  }
  p {
    color: #fff;
    font-size: 32px;
  }
}

.loginContainer {
  overflow-y: auto;
  position: relative;
  margin-top: -2rem;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  .loginBox {
    width: 50%;
    margin: 0 auto;
  }
}
.signUpLink {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 15px 24px;
  background: #fff;
  p {
    margin: 0;
    z-index: 999;
  }
}
.required {
  color: #ff4d4f;
  margin-right: 4px;
}
.error {
  color: #ff4d4f;
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.phoneInput {
  input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  input:focus,
  input:active {
    border: 1px solid #084aac;
    outline: none;
  }

  input:hover {
    border: 1px solid #084aac;
  }
}

.errorInput {
  input,
  input:hover,
  input:active {
    border: 1px solid #ff4d4f;
  }
}

@media screen and (max-width: 768px) {
  .bannerContainer {
    display: none;
  }
  .loginContainer {
    width: 99vw;
    height: 100vh;
    overflow-x: hidden;
    overflow: hidden;
  }
  .title {
    display: block;
  }
}
