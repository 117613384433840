.settings__container {
  padding: 24px;
  width: 100%;
  .settings__header {
    font-family: Roboto;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .link__container {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    .link__title {
      font-size: 18px;
      margin-bottom: 0;
      padding-top: 24px;
      cursor: pointer;
      &::after {
        display: inline-block;
        padding-left: 8px;
        content: "\0279E"; // arrow right unicode
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
      }

      &:hover {
        &::after {
          -webkit-transform: translateX(6px);
          -moz-transform: translateX(6px);
          -ms-transform: translateX(6px);
          -o-transform: translateX(6px);
          transform: translateX(6px);
        }
      }
    }
  }
}

// Text Setting Page

.text__container {
  padding: 24px;
  width: 100%;
  .text__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text__title {
      font-family: Roboto;
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 20px;
      span {
        font-size: 16px;
        font-weight: normal;
      }
    }
    .text__btn {
      background: #16293b;
      color: #fff;
      text-transform: capitalize;
      font-size: 16px;
    }
  }
  .text__field {
    margin-bottom: 15px;
    .MuiOutlinedInput-root {
      border-radius: 15px;
      background: #fff;
    }
  }
}

// Device Setting Page

.device__container {
  padding: 24px;
  width: 100%;
  .device__title {
    font-family: Roboto;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
  .device__box__title {
    font-family: Roboto;
    // font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 20px;
    background: white;
    padding: 16px;
    border-radius: 20px; 
    text-align: center;
    box-shadow: 0px 10px 40px rgba(212, 217, 232, 0.5) !important;
    display: flex;
    flex-direction: column;
    min-width: 180px;
    font-size: 2.3rem;
    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
}



.dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete__btn {
  background: #EB5757 !important;
  box-shadow: none !important;
  border-radius: 5px !important; 
  padding: 8px 16px !important;
  text-transform: capitalize !important;
}
.cancel__btn {
  color: black !important;
  border: 1px solid black !important;
  padding: 8px 16px !important;
  text-transform: capitalize !important;
}

// Image Setting Page

.image__container {
  padding: 24px;
  width: 100%;
  .image__title {
    font-family: Roboto;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
  .brand__container {
    width: 100%;
    padding: 32px 0px;
    .brand__grid {
      padding: 16px;
    }
    .brand__title {
      font-size: 1.5rem;
      font-family: Roboto;
      color: #16293B;
    }
  }
  .brand__inner__container {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      max-width: 300px;
      min-height: 300px;
      margin-bottom: 15px;
      max-height: 300px;
    }
    .brand__type {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 5px;
    }
    .brand__anchor {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
