.ant-checkbox-group-item {
  display: block !important;
  margin-right: 0;
}

.ant-checkbox-wrapper {
  display: flex !important;
}

.ant-picker-dropdown {
  z-index: 9999 !important;
}

@font-face {
  font-family: "Patua";
  src: url("../fonts/Patua_One/PatuaOne-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("https://imagebucket-fella.s3.ap-south-1.amazonaws.com/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("https://imagebucket-fella.s3.ap-south-1.amazonaws.com/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: Open sans;
  src: url(https://imagebucket-fella.s3.ap-south-1.amazonaws.com/fonts/Open_Sans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu/Ubuntu-Regular.ttf");
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span {
  font-family: "Roboto" !important;
}

// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
//   content: 'Heading 1';
// }


.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]:before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]:before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]:before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]:before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]:before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="48px"]::before {
  content: "48px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="64px"]:before,
.ql-snow .ql-size #ql-picker-options-0 .ql-picker-item[data-value="64px"]::before {
  content: "64px";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="roboto"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="roboto"]::before {
  content: "Roboto";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lato"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lato"]::before {
  content: "Lato";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="open-sans"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="open-sans"]::before {
  content: "Open sans";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="verdana"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
  content: "Verdana, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
  content: "Courier, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="tahoma"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
  content: "Tahoma, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="palatino"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="palatino"]::before {
  content: "Palatino, sans-serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="geneva"]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="geneva"]::before {
  content: "Geneva, sans-serif";
}

// @font-face{
//     font-family: 'Roboto regular';
//     src: url('../fonts/Roboto/Roboto-Regular.ttf');
// }

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.ql-font-roboto {
  font-family: Roboto;
}

.ql-font-lato {
  font-family: Lato;
}

.ql-font-open-sans {
  font-family: Open sans !important;
}

.ql-font-arial {
  font-family: "Arial", sans-serif !important;
}

.ql-font-courier {
  font-family: "Courier", sans-serif !important;
}

.ql-font-helvetica {
  font-family: "Helvetica", sans-serif !important;
}

.ql-font-verdana {
  font-family: "Verdana", sans-serif !important;
}

.ql-font-tahoma {
  font-family: "Tahoma", sans-serif !important;
}

.ql-font-georgia {
  font-family: "Georgia", sans-serif !important;
}

.ql-font-palatino {
  font-family: "Palatino", sans-serif !important;
}

.ql-font-geneva {
  font-family: "Geneva", sans-serif !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: rgb(53, 53, 53) !important;
  background: rgba(255, 255, 255, 0.33) !important;
  border-color: rgba(255, 255, 255, 0.481) !important;
}

.title-case {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.upper-case {
  text-transform: uppercase;
}

.top-left {
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 1;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
}

.mw80 {
  max-width: 80%;
  margin: 0 auto;
}

.container {
  padding: 20px 10px;
}

.stickyToolbar {
  position: fixed;
  height: 4.3rem;
  width: 100%;
  background: #000000;
  padding: 0.8rem;
  z-index: 1;
  top: 0;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100% !important;
}

.w25 {
  width: 25%;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.ptb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.white {
  color: #fff !important;
}

.ant-message {
  z-index: 1500 !important;
}

.center {
  text-align: center;
}

.danger {
  color: red;
}

.table-head {
  background-color: #18283b;

  th {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
  }

  svg {
    color: #fff;
  }
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mtb10 {
  margin: 10px 0 !important;
}

.mrl10 {
  margin: 10px 0;
}

.plr10 {
  padding: 0 10px;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.showTop {
  z-index: 9999 !important;
}

.secondary-text {
  color: rgb(116, 116, 116);
}

.link {
  color: rgb(0, 102, 255);
}

.link:hover {
  color: rgb(13, 0, 255);
}

.show-on-web {
  display: block;
}

.show-on-mobile {
  display: none;
}

@media screen and (max-width: 740px) {
  .show-on-web {
    display: none;

  }

  .show-on-mobile {
    display: block;
  }
}